
import { Vue, Options } from "vue-class-component";
import SidebarWrapper from "@/common/components/sidebar-wrapper/SidebarWrapper.vue";
import Pagetree from "@/common/components/pagetree/Pagetree.vue";
import { guideService } from "./services/guide-service";
import { RouteLocation } from "vue-router";
import { getPagetreeItemByKey } from "@/common/models/pagetree";
import LoadingCircle from "@/common/components/loading-circle/LoadingCircle.vue";
import { IContentTreeModel, IContentTreeItem } from "./models/content-tree";
import { Watch } from "vue-property-decorator";
import Mark from "mark.js";

@Options<GuideView>({
	components: {
		SidebarWrapper,
		Pagetree,
		LoadingCircle
	}
})
export default class GuideView extends Vue {
	public pagetree: IContentTreeModel | null = null;
	public selectedItem: IContentTreeItem | null = null;
	public isPagetreeLoading: boolean = false;
	public hideIFrame: boolean = false;

	get currentSection(): string {
		return (this.$route.params.section as string) ?? "";
	}

	get currentContentId(): string | null {
		return (this.$route.query["ContentId"] as string) ?? null;
	}

	get iFrameSrc(): string | null {
		if (this.currentContentId || this.pagetree?.rootContentId) {
			return (
				"/_/Guide/Content/ConfluenceContent?ContentId=" +
				(this.currentContentId || this.pagetree?.rootContentId)
			);
		} else {
			return null;
		}
	}

	@Watch("iFrameSrc")
	public onIFrameSrcChange(): void {
		//Remove iframe from dom, while changing src, to avoid pushing state to history.
		this.hideIFrame = true;
		this.$nextTick(function () {
			this.hideIFrame = false;
		});
	}

	get pagetreeHasMultipleNodes(): boolean {
		return !!(this.pagetree?.childItems && this.pagetree.childItems.length > 0);
	}

	async mounted(): Promise<void> {
		await this.reloadPagetree();
	}

	private async reloadPagetree(): Promise<void> {
		if (!this.currentSection) {
			return;
		}

		this.isPagetreeLoading = true;
		const pagetree = await guideService.getContentTree(this.currentSection);
		if (this.currentContentId) {
			this.selectedItem = getPagetreeItemByKey(this.currentContentId, pagetree);
		}
		this.pagetree = pagetree;
		this.isPagetreeLoading = false;
	}

	public onPagetreeItemSelected(item: IContentTreeItem): void {
		this.$router.push({
			query: { ContentId: item.key }
		});
	}

	@Watch("$route")
	public onRouteChange(to: RouteLocation, from: RouteLocation): void {
		if (to.params.section !== from.params.section) {
			this.$nextTick(() => {
				this.reloadPagetree();
			});
		} else if (to.query["ContentId"] !== from.query["ContentId"]) {
			const contentId = to.query["ContentId"] as string;
			if (contentId) {
				this.selectedItem = getPagetreeItemByKey(contentId, this.pagetree);
			} else {
				this.selectedItem = null;
			}
		}
	}

	public onIFrameLoad(event: Event): void {
		this.$nextTick(() => {
			const iframe = event.target as HTMLIFrameElement;
			if (!iframe.contentDocument) {
				return;
			}
			const contentDocumentHeight =
				iframe.contentDocument.documentElement.getBoundingClientRect().height ||
				0;
			iframe.height = (contentDocumentHeight + 20).toString();

			const hash = this.$route.query["IFrameHash"] as string;
			if (hash) {
				iframe.contentDocument
					.getElementById(decodeURI(hash))
					?.scrollIntoView({ behavior: "smooth" });
			}

			this.performMark(iframe);
		});
	}

	private performMark(iframe: HTMLIFrameElement): void {
		const keyword = this.$route.query["SearchPhrase"] as string;
		if (!keyword) {
			return;
		}

		const iframeMainContent =
			iframe.contentDocument?.getElementById("main-content");
		if (!iframeMainContent) {
			return;
		}

		const markInstance = new Mark(iframeMainContent);
		markInstance.unmark({
			done: () => {
				markInstance.mark(keyword);
			}
		});
	}
}
