import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-011b04af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-slot" }
const _hoisted_2 = {
  key: 0,
  class: "pagetree-header"
}
const _hoisted_3 = { class: "pagetree-container" }
const _hoisted_4 = { class: "content-container" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagetree = _resolveComponent("pagetree")!
  const _component_loading_circle = _resolveComponent("loading-circle")!
  const _component_sidebar_wrapper = _resolveComponent("sidebar-wrapper")!

  return (_openBlock(), _createBlock(_component_sidebar_wrapper, {
    showSidebar: _ctx.pagetreeHasMultipleNodes,
    class: "sidebar-wrapper"
  }, {
    sidebar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.pagetree.rootContentTitle)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.pagetree.rootContentTitle), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.pagetree)
            ? (_openBlock(), _createBlock(_component_pagetree, {
                key: 0,
                selectedItem: _ctx.selectedItem,
                isLoading: _ctx.isPagetreeLoading,
                pagetree: _ctx.pagetree,
                "onUpdate:selectedItem": _ctx.onPagetreeItemSelected
              }, null, 8, ["selectedItem", "isLoading", "pagetree", "onUpdate:selectedItem"]))
            : (_openBlock(), _createBlock(_component_loading_circle, { key: 1 }))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.iFrameSrc && !_ctx.hideIFrame)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              src: _ctx.iFrameSrc,
              height: "2000",
              title: "selectedItem.primaryText",
              onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onIFrameLoad && _ctx.onIFrameLoad(...args)))
            }, null, 40, _hoisted_5))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["showSidebar"]))
}